import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import {
  FaucetHealthResponse,
  FaucetHealthStatus,
} from "../libraries/FaucetRequestTypes";
import { AiFillCheckCircle } from "react-icons/ai";
import { ImCross } from "react-icons/im";

function FaucetHealthComponent(props: { faucetHealth: FaucetHealthResponse }) {
  function overlayComponent(): JSX.Element {
    const baseStyle = {
      padding: "5px",
      color: "white",
      borderRadius: 4,
      fontSize: 12,
      margin: 3,
    };
    switch (props.faucetHealth.status) {
      case FaucetHealthStatus.UNHEALTHY: {
        return (
          <div
            style={{
              ...baseStyle,
              background: "rgba(255,37,0,.6)",
            }}
          >
            <ImCross /> {props.faucetHealth.content}
          </div>
        );
      }
      case FaucetHealthStatus.HEALTHY: {
        return (
          <div
            style={{
              ...baseStyle,
              background: "rgba(39,155,104,0.7)",
            }}
          >
            <AiFillCheckCircle /> {props.faucetHealth.content}
          </div>
        );
      }
    }
  }
  function renderTooltip(props: OverlayInjectedProps) {
    return (
      <Tooltip {...props} id={"alchemy-health-popover-contained"}>
        {overlayComponent()}
      </Tooltip>
    );
  }
  const style = {
    margin: "-1px",
    height: "12px",
    width: "12px",
    backgroundColor:
      props.faucetHealth.status === FaucetHealthStatus.HEALTHY
        ? "#279B68"
        : "rgb(232 34 0)",
    borderRadius: "50%",
    display: "inline-block",
  };
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 1000 }}
      overlay={renderTooltip}
    >
      <div style={style}></div>
    </OverlayTrigger>
  );
}

export default FaucetHealthComponent;
