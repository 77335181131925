import axios, { AxiosError } from "axios";
import { formURL } from "./Util";
import { HelperStrings, UrlPaths } from "../../shared/constants";
import {
  AuthCheckProps,
  AuthState,
  getStoredAuthToken,
} from "../components/AuthCheckComponent";
import {
  AuthedUser,
  AuthedUserError,
  UserProperties,
} from "../../shared/models/types";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FaucetLogger from "./FaucetLogger";

const AUTH_REFRESH_PARAM = "authRefresh";
const AUTH_TOKEN = "authToken";

const createUrl = (origin: string, params: Record<string, string>) => {
  const urlObj = new URL(origin);
  urlObj.search = new URLSearchParams(params).toString();
  return urlObj.toString();
};

export default function useAuth(
  authUrl: string,
  apiUrl: string,
  onboardingUrl: string,
  affiliateToken: string,
): AuthCheckProps {
  const [makingRequest, setMakingRequest] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [authState, setAuthState] = useState<AuthState>(
    AuthState.UNAUTHENTICATED,
  );
  const [firstName, setFirstName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [userProperties, setUserProperties] = useState<UserProperties>();

  const location = useLocation();
  const navigate = useNavigate();

  const signupRedirectUrl = createUrl(window.location.href, {
    [AUTH_REFRESH_PARAM]: "True",
  });
  const signupUrl = createUrl(onboardingUrl, {
    a: affiliateToken,
    redirectUrl: signupRedirectUrl,
  });
  const logoutUrl = createUrl(`${authUrl}/logout`, {
    immediate: "true",
    redirectUrl: window.location.href,
  });
  const loginUrl = createUrl(authUrl, {
    redirectUrl: `${window.location.origin}${window.location.pathname}`,
  });

  const redirectToUrl = (url: string) => {
    window.location.href = url;
  };

  const loginRedirect = useCallback(() => {
    redirectToUrl(signupUrl);
  }, [signupUrl]);

  const logout = useCallback(() => {
    localStorage.removeItem(AUTH_TOKEN);
    setAuthState(AuthState.UNAUTHENTICATED);
    setErrorMessage("");
    setMakingRequest(false);
    window.location.href = logoutUrl;
  }, [logoutUrl]);

  // Check that the auth token is valid by checking with Authchemy.
  const checkAuth = useCallback(async (): Promise<void> => {
    const authToken = getStoredAuthToken();
    if (makingRequest || !authToken) {
      return;
    }
    setAuthState(AuthState.LOADING);
    setMakingRequest(true);
    setErrorMessage("");
    setFirstName("");
    setUserId("");

    try {
      const userResponse: AuthedUser | AuthedUserError =
        await fetchUserAuth(apiUrl);

      if (userResponse.type === "AuthedUser" && userResponse.teamId) {
        setAuthState(AuthState.AUTHENTICATED);
        setFirstName(userResponse.firstName);
        setUserId(userResponse.id.toString());
        const properties = await fetchUserProperties(apiUrl);
        setUserProperties(properties);
        FaucetLogger.setAnalyticsUser(userResponse);
      } else if (userResponse.type === "AuthedUser") {
        // Auth-ed user but no team means they haven't finished onboarding, come on man (joe biden voice)
        setAuthState(AuthState.INCOMPLETE);
        setFirstName(userResponse.firstName);
        setErrorMessage(HelperStrings.ONBOARDING_ERROR_MSG);
        setUserId(userResponse.id.toString());
        FaucetLogger.setAnalyticsUser(userResponse);
      } else {
        setAuthState(AuthState.ERROR);
        setErrorMessage(userResponse.message);

        // Clear auth token if it's expired.
        if (userResponse.expired) {
          localStorage.removeItem(AUTH_TOKEN);
        }
      }
    } catch (err: unknown) {
      setAuthState(AuthState.ERROR);
      localStorage.removeItem(AUTH_TOKEN);
      setErrorMessage("Failed to authenticated user.");
    }
    setMakingRequest(false);
  }, [makingRequest, apiUrl]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const inboundAuthToken = queryParams.get(AUTH_REFRESH_PARAM);
    if (inboundAuthToken) {
      redirectToUrl(loginUrl);
    }
  }, [location.search, loginUrl]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // Store auth token in local storage if Authchemy passed us one, then delete
    // it from the URL.
    const inboundAuthToken = queryParams.get(AUTH_TOKEN);
    if (inboundAuthToken) {
      localStorage.setItem(AUTH_TOKEN, inboundAuthToken);
      navigate(window.location.pathname);
    }
    checkAuth().catch((err: AxiosError) => {
      console.error("checkAuth error", err);
      throw err;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authState,
    errorMessage,
    firstName,
    userId,
    loginRedirect,
    logout,
    userProperties,
  };
}

async function fetchUserAuth(
  apiUrl: string,
): Promise<AuthedUser | AuthedUserError> {
  return axios
    .get(
      formURL(UrlPaths.API_V1.REQUEST_AUTH_CHECK, apiUrl),
      generateAuthHeader(),
    )
    .then(
      (result): AuthedUser =>
        ({
          ...result.data,
          type: "AuthedUser",
        }) as AuthedUser,
    )
    .catch((err: AxiosError<{ error?: string }>): AuthedUserError => {
      if (err.response?.status === 401) {
        return {
          expired: true,
          message: "Authentication expired",
          type: "AuthedUserError",
        };
      }
      const message = `Sorry, couldn't authenticate. ${
        err.response?.data?.error || ""
      }`;
      return {
        expired: false,
        message: message,
        type: "AuthedUserError",
      };
    });
}

async function fetchUserProperties(apiUrl: string): Promise<UserProperties> {
  const response = await axios.get<UserProperties>(
    formURL(UrlPaths.API_V1.REQUEST_USER_PROPERTIES, apiUrl),
    generateAuthHeader(),
  );
  return response.data;
}

const generateAuthHeader = () => {
  return {
    headers: {
      authorization: `Bearer ${getStoredAuthToken() || ""}`,
    },
  };
};
