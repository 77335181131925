import axios, { AxiosError } from "axios";
import { UrlPaths } from "../../shared/constants";
import {
  EasRequestParams,
  EasResponse,
  FaucetChainNetworkKey,
  FaucetRequestErrorResponse,
  FaucetRequestParams,
  FaucetRequestResponse,
  FaucetRequestSuccessResponse,
  FaucetResponseStatus,
} from "../../shared/models/types";
import FaucetLogger from "./FaucetLogger";
import { FaucetHealthResponse, FaucetHealthStatus } from "./FaucetRequestTypes";
import { formURL } from "./Util";
import { getStoredAuthToken } from "../components/AuthCheckComponent";
import CommonUtils from "../../shared/commonUtils";

const FaucetTransferClient = {
  async requestHealth(
    chainNetwork: FaucetChainNetworkKey,
    apiUrl: string,
  ): Promise<FaucetHealthResponse> {
    return await axios
      .get(formURL(`/${chainNetwork}${UrlPaths.APP_HEALTH}`, apiUrl))
      .then(function () {
        return {
          status: FaucetHealthStatus.HEALTHY,
          content: "The faucet is healthy",
        };
      })
      .catch(function () {
        return {
          status: FaucetHealthStatus.UNHEALTHY,
          content: "The faucet is unhealthy",
        };
      });
  },

  async requestEasAttestation(
    chainNetwork: FaucetChainNetworkKey,
    apiUrl: string,
    params: EasRequestParams,
  ): Promise<EasResponse> {
    const headers = {
      headers: {
        authorization: `Bearer ${getStoredAuthToken() || ""}`,
      },
    };
    try {
      await axios.post(
        formURL(`/${chainNetwork}${UrlPaths.API_V1.EAS_ATTESTATION}`, apiUrl),
        params,
        headers,
      );
      return {};
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return err.response?.data as EasResponse;
      }

      throw err;
    }
  },

  async requestTransfer(
    chainNetwork: FaucetChainNetworkKey,
    apiUrl: string,
    params: FaucetRequestParams,
  ): Promise<{
    serverResponse: FaucetRequestResponse;
    clientRequestId: string;
  }> {
    const headers = {
      headers: {
        authorization: `Bearer ${getStoredAuthToken() || ""}`,
      },
    };
    return await axios
      .post(
        formURL(
          `/${chainNetwork}${UrlPaths.API_V1.REQUEST_TRANSFER_PATH}`,
          apiUrl,
        ),
        params,
        headers,
      )
      .then(function (response) {
        const successResponse = response.data as FaucetRequestSuccessResponse;
        return {
          serverResponse: successResponse,
          clientRequestId: params.clientRequestId,
        };
      })
      .catch(function (err: Error | AxiosError) {
        const networkInfo =
          CommonUtils.getChainNetworkInfoWithNetworkKey(chainNetwork)!;
        let failResponse: FaucetRequestErrorResponse = {
          networkInfo,
          toAddress: params.toAddress,
          resolvedToAddress: "UNKNOWN",
          message: err.message ? err.message : "An Unknown Error Occured.",
          code: 400,
          responseStatus: FaucetResponseStatus.ERROR,
        };
        if (
          axios.isAxiosError(err) &&
          err.response &&
          typeof err.response.data === "object" &&
          "message" in err.response.data
        ) {
          failResponse = err.response.data as FaucetRequestErrorResponse;
        }

        FaucetLogger.logRequestFail(
          params.clientRequestId,
          failResponse,
          params.alchemyUserId,
        );
        return {
          serverResponse: failResponse,
          clientRequestId: params.clientRequestId,
        };
      });
  },
};

export default FaucetTransferClient;
