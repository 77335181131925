import { Share } from "react-twitter-widgets";
import React from "react";
import {
  ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK,
  FaucetAppNetworkInfo,
} from "../../shared/models/types";
import { HelperStrings } from "../../shared/constants";

interface TwitterProps {
  size: string;
  appNetworkInfo: FaucetAppNetworkInfo;
}

function FaucetTwitterComponent(props: TwitterProps): JSX.Element {
  let size;
  const chainNetwork = props.appNetworkInfo.id;
  const faucetUrl = `${HelperStrings.ALCHEMY_COM_FAUCETS_URL}/${ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK[chainNetwork]}`;
  const personalizedTexts = [
    `Boom! Just got some free ${props.appNetworkInfo.networkDisplayName} ${props.appNetworkInfo.tokenName} on ${faucetUrl} in literally 1 minute.`,
    `Hands down, ${faucetUrl} is the best faucet around!`,
    `Just started using ${faucetUrl} and it worked like a breeze!\n\nShoutout to Alchemy for making it happen.`,
    `The rain animation\u{1F4A6}\u{1F525}. Huge props to Alchemy!\n\n${faucetUrl}\n\u{2B06}\u{2B06}\u{2B07}\u{2B07}\u{2B05}\u{27A1}\u{2B05}\u{27A1}\u{1F171}\u{1F170}`,
    `Thanks so much to the folks at Alchemy!\n\nJust used your faucet and it worked like a charm!\n\nYou can find it here: ${faucetUrl} \u{2618}`,
  ];
  const personalizedText =
    personalizedTexts[Math.floor(Math.random() * personalizedTexts.length)];
  if (props && props.size) {
    size = props.size;
  } else {
    size = "large";
  }
  return (
    <Share
      url=" "
      options={{
        text: personalizedText,
        hashtags: "",
        showCount: false,
        size: size,
      }}
    />
  );
}

export default React.memo(FaucetTwitterComponent);
