import { FaucetAppNetworkInfo } from "../../shared/models/types";
import { AuthCheckProps, AuthState } from "./AuthCheckComponent";
import { ReactNode } from "react";
import { getDeterministicNewDayElement, isActiveTeam } from "../libraries/Util";

const getActiveTeamUpsell = (
  appNetwork: FaucetAppNetworkInfo,
  userId: string,
): ReactNode => {
  const upsells = [
    <span key={1}>
      Speed up your RPC requests by sending them{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://dashboard.alchemy.com"}
      >
        through Alchemy!
      </a>{" "}
    </span>,
    <span key={2}>
      Get notified for any blockchain event {appNetwork.tokenName} when you use{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://www.alchemy.com/notify"}
      >
        Alchemy Notify
      </a>
      !
    </span>,
    <span key={4}>
      Try out the most effective blockchain sdk - the{" "}
      <a target="_blank" rel="noreferrer" href={"https://www.alchemy.com/sdk"}>
        Alchemy SDK
      </a>{" "}
      !
    </span>,
    <span key={5}>
      Fetch any NFT data simply and easily with the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={"https://www.alchemy.com/nft-api"}
      >
        Alchemy NFT API
      </a>{" "}
    </span>,
  ];
  // Shows the same upsell for that day, changes each new day
  const modular = getDeterministicNewDayElement(parseInt(userId));
  return upsells[modular % upsells.length];
};

export function FaucetRequestUpsell({
  appNetwork,
  authCheck,
}: {
  appNetwork: FaucetAppNetworkInfo;
  authCheck: AuthCheckProps;
}) {
  if (authCheck.authState === AuthState.AUTHENTICATED) {
    if (
      isActiveTeam(authCheck.userProperties) &&
      appNetwork.activeTeamUpsellEnabled
    ) {
      return (
        <div className="alchemy-faucet-login-description">
          <p>
            &#9989; You&apos;re an active Alchemy user! Enjoy{" "}
            {appNetwork.activeTeamDripAmount} when you make a request.
          </p>
        </div>
      );
    }
    // User is not active
    return (
      <div className="alchemy-faucet-login-description">
        <p>
          &#9989; Alchemy account connected, receive {appNetwork.authDripAmount}
          !{" "}
          {appNetwork.activeTeamUpsellEnabled &&
            getActiveTeamUpsell(appNetwork, authCheck.userId)}
        </p>
      </div>
    );
  }
  // Unauthenticated
  return (
    <p className="alchemy-faucet-login-description">
      <span
        style={{
          textDecoration: "underline",
          color: "rgba(89,131,247,1)",
          cursor: "pointer",
        }}
        onClick={authCheck.loginRedirect}
      >
        Please signup or login
      </span>{" "}
      with Alchemy to request {appNetwork.tokenName}. It&apos;s free!
    </p>
  );
}
