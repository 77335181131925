import { memo, useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert, Button } from "react-bootstrap";
import {
  FaucetAppNetworkInfo,
  FaucetClientServerData,
} from "../../../shared/models/types";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import FaucetLoadingSpinner from "../FaucetLoadingSpinner";
import FaucetTransferClient from "../../libraries/FaucetTransferClient";
import { FaucetRequestItem } from "../../libraries/FaucetRequestTypes";
import { orderBy } from "lodash";
import { HelperStrings } from "../../../shared/constants";

interface SuccessModalProps {
  serverData: FaucetClientServerData;
  appNetwork: FaucetAppNetworkInfo;
  requestItems: FaucetRequestItem[];
  show: boolean;
  handleClose: () => void;
}

const OptimismSuccessModal = memo(function OptimismSuccessModal({
  serverData,
  appNetwork,
  requestItems,
  show,
  handleClose,
}: SuccessModalProps) {
  const [attesting, setAttesting] = useState(false);
  const [error, setError] = useState("");

  const attestEas = useCallback(async () => {
    setAttesting(true);
    setError("");

    try {
      const toAddress = orderBy(requestItems, ["requestTime"], ["desc"])[0]
        .toAddress;
      const response = await FaucetTransferClient.requestEasAttestation(
        appNetwork.id,
        serverData.API_SERVER_URL,
        {
          toAddress: toAddress,
        },
      );
      if (response.error) {
        setError(response.error);
      } else {
        handleClose();
        window.open(HelperStrings.OPTIMISM_SUPER_CHAIN_FAUCET_URL, "_blank");
      }
    } finally {
      setAttesting(false);
    }
  }, [appNetwork.id, handleClose, requestItems, serverData.API_SERVER_URL]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="justify-content-center"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title w-100 text-center"
            style={{ textTransform: "capitalize" }}
          >
            Get additional Optimism Sepolia on Optimism&apos;s Superchain Faucet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={`${process.env.PUBLIC_URL}/opt_superchain.svg`}
            alt={"Optimism Superchain Banner"}
            width="100%"
            height="300px"
            style={{ marginBottom: "1rem" }}
          />
          <OptimismSuperchainFaucetInfo />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {error && <Error error={error} />}
          <Button
            size="lg"
            variant="primary"
            className={classNames(
              "alchemy-faucet-button optimism-superchain-faucet-button",
            )}
            onClick={() => {
              void attestEas();
            }}
            disabled={attesting}
          >
            {attesting ? (
              <TransitionGroup className={"alchemy-faucet-table-body"}>
                <CSSTransition key={"empty"} timeout={300}>
                  <FaucetLoadingSpinner
                    width={30}
                    height={30}
                    thickness={4}
                    margin={"auto"}
                    color={"white"}
                  />
                </CSSTransition>
              </TransitionGroup>
            ) : (
              <TransitionGroup className={"alchemy-faucet-table-body"}>
                <CSSTransition key={"empty"} timeout={300}>
                  <span>Claim Test Tokens</span>
                </CSSTransition>
              </TransitionGroup>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

const Error = (props: { error: string }) => {
  return (
    <Alert className="optimism-superchain-error" closeVariant="white">
      {props.error}
    </Alert>
  );
};

const OptimismSuperchainFaucetInfo = () => {
  return (
    <div className="optimism-superchain-info-steps-container">
      <p>
        1. If you haven&apos;t already, add your{" "}
        <a
          href={HelperStrings.ALCHEMY_BILLING_UPDATE_URL}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          billing info to your Alchemy account.
        </a>
      </p>
      <p>
        2. Claim your extra Optimism Sepolia ETH from the Optimism Superchain
        Faucet by selecting &quot;Claim Test Tokens&quot;
      </p>
    </div>
  );
};

export default OptimismSuccessModal;
