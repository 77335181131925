import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getStoredAuthToken } from "../components/AuthCheckComponent";
import useServerData from "./useServerData";
import { z } from "zod";

const FlagsSchema = z.object({
  faucet_dashboard_promo: z
    .object({ test: z.boolean() })
    .catch({ test: false }),
});

type FlagsSchemaTypes = z.infer<typeof FlagsSchema>;

type Flag = keyof FlagsSchemaTypes;

export default function useStatsigFlag<
  T extends Flag,
  Data extends Partial<Record<T, FlagsSchemaTypes[T]>>,
>(flag: T, opts?: Partial<UseQueryOptions<Data>>) {
  const { WEBAPP_API_SERVER_URL } = useServerData();

  const userFields = {
    userAgent: navigator.userAgent,
    locale: navigator.languages ? navigator.languages[0] : navigator.language,
  };

  return useQuery({
    ...opts,
    queryKey: ["flag", flag],
    queryFn: async () => {
      const payload = { flagIds: [flag], userFields };
      const encodedPayload = encodeURIComponent(JSON.stringify(payload));

      const url = `${WEBAPP_API_SERVER_URL}/trpc/flags.getFlags?input=${encodedPayload}`;
      const authToken = getStoredAuthToken();

      const response = await fetch(url, {
        credentials: "include",
        headers: authToken
          ? { authorization: `Bearer ${getStoredAuthToken()}` }
          : undefined,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch flag");
      }

      const body = (await response.json()) as {
        result: { data: Data };
      };

      return body.result.data;
    },
    select: (data) => FlagsSchema.shape[flag].parse(data[flag]),
  });
}
