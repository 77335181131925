import { useCallback, useMemo } from "react";
import {
  BasicFaucetAppNetworkInfo,
  FaucetChainNetworkKey,
} from "../../shared/models/types";
import Select, { SingleValue } from "react-select";

interface NetworkSelectProps {
  selectedNetworkId: FaucetChainNetworkKey;
  setSelectedNetworkId: (networkId: FaucetChainNetworkKey) => void;
  networkConfig: BasicFaucetAppNetworkInfo[];
}

interface NetworkOption {
  value: FaucetChainNetworkKey;
  label: string;
}

export function NetworkSelect({
  networkConfig,
  selectedNetworkId,
  setSelectedNetworkId,
}: NetworkSelectProps) {
  const options: NetworkOption[] = useMemo(
    () =>
      networkConfig.map((networkInfo) => ({
        value: networkInfo.id,
        label: networkInfo.fullName,
      })),
    [networkConfig],
  );

  const onChange = useCallback(
    (newValue: SingleValue<NetworkOption>) => {
      if (newValue) {
        setSelectedNetworkId(newValue.value);
      }
    },
    [setSelectedNetworkId],
  );

  return (
    <Select<NetworkOption>
      options={options}
      value={options.find((option) => option.value === selectedNetworkId)}
      onChange={onChange}
      styles={{
        singleValue: (baseStyles) => ({
          ...baseStyles,
          fontSize: 14,
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          minWidth: 200,
        }),
      }}
      menuPortalTarget={document.body}
    />
  );
}
