import { memo, useRef } from "react";
import { Alert } from "react-bootstrap";

const FaucetBanner = memo(function FaucetBanner({
  show,
  innerHtml,
  onClose,
}: {
  show: boolean;
  innerHtml?: string;
  onClose: () => void;
}) {
  const innerHtmlRef = useRef<string>();
  if (innerHtml) {
    innerHtmlRef.current = innerHtml;
  }
  return (
    <div
      className={
        show ? "faucet-banner-container show" : "faucet-banner-container"
      }
    >
      <Alert
        className="faucet-banner"
        onClose={onClose}
        dismissible
        closeVariant="white"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: innerHtmlRef.current ?? "<br/>",
          }}
        />
      </Alert>
    </div>
  );
});

export default FaucetBanner;
