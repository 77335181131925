import { createContext, useContext } from "react";
import { FaucetClientServerData } from "../../shared/models/types";

export const ServerDataContext = createContext<
  FaucetClientServerData | undefined
>(undefined);

export interface ServerDataContextProviderProps {
  children: React.ReactNode;
  serverData: FaucetClientServerData;
}

export default function useNetworkInfo(): FaucetClientServerData {
  const networkInfo = useContext(ServerDataContext);
  if (networkInfo) {
    return networkInfo;
  }
  throw Error("Missing ServerDataContextProvider");
}
