import { memo } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

interface DashboardPromoModalProps {
  show: boolean;
  handleClose: () => void;
}

const DashboardPromoModal = memo(function DashboardPromoModal({
  show,
  handleClose,
}: DashboardPromoModalProps) {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="justify-content-center"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title w-100 text-center"
            style={{ textTransform: "capitalize" }}
          >
            ✅ Transaction successful!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"alchemy-faucet-upsell-modal-body"}>
            <img
              src="https://static.alchemyapi.io/images/marketing/dashboard-promo-modal.png"
              alt=""
              style={{
                display: "block",
                margin: "12px auto",
                paddingBottom: "12px",
                aspectRatio: "2",
                width: "90%",
                maxWidth: 640,
              }}
            />
            <p>Now that you’ve got some test ETH, time to deploy 🚀</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            style={{ width: "250px" }}
            as="a"
            href="https://dashboard.alchemy.com/apps/latest?utm_source=faucets&utm_campaign=dashboard-promo&utm_medium=website"
            target="_blank"
            rel="noreferrer"
          >
            Start Building
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default DashboardPromoModal;
