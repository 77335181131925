interface FaucetAnnouncementBannerProps {
  children: React.ReactNode;
}

const FaucetAnnouncementBanner = ({
  children,
}: FaucetAnnouncementBannerProps) => {
  return (
    <div className="faucet-announcement-container">
      <span className="faucet-announcement-text">{children}</span>
    </div>
  );
};

export const OptimismSuperchainAnnouncementBanner = () => {
  return (
    <FaucetAnnouncementBanner>
      Need additional Optimism Sepolia ETH? Get more using Optimism&apos;s
      Superchain Faucet, <a href="#access-optimism-superchain">learn more</a>.
    </FaucetAnnouncementBanner>
  );
};

export default FaucetAnnouncementBanner;
