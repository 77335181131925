const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    void import("web-vitals").then(
      ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        getCLS(onPerfEntry);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        getFID(onPerfEntry);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        getFCP(onPerfEntry);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        getLCP(onPerfEntry);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        getTTFB(onPerfEntry);
      },
    );
  }
};

export default reportWebVitals;
