import {
  BaseBlockChain,
  FaucetChainNetworkKey,
  FaucetRequestNetwork,
  FaucetRequestNetworkInfo,
} from "./models/types";

const CommonUtils = {
  getChainNetworkInfoWithNetworkKey(
    networkKey: FaucetChainNetworkKey,
  ): FaucetRequestNetworkInfo | undefined {
    // Network keys should be in the format `${chain}_${network}`, where `chain` can have multiple words separated by `_`.
    // So we split by the final `_` to get the chain and network.
    // This would be easier if we were using the NetworkId...
    const chainNetworkDelimiterIdx = networkKey.lastIndexOf("_");
    if (chainNetworkDelimiterIdx === -1) {
      return undefined;
    }

    return {
      chain: networkKey
        .substring(0, chainNetworkDelimiterIdx)
        .toLowerCase() as BaseBlockChain,
      network: networkKey
        .substring(chainNetworkDelimiterIdx + 1)
        .toLowerCase() as FaucetRequestNetwork,
    };
  },
};

export default CommonUtils;
