import {
  FaucetRequestErrorResponse,
  FaucetRequestSuccessResponse,
} from "../../shared/models/types";

export const CLIENT_REQUEST_ID_PREFIX = "alch_faucet_storage_v2_";

export enum FaucetRequestStatus {
  PENDING = "Pending",
  IDLE = "Idle",
}

export enum FaucetHealthStatus {
  HEALTHY = "HEALTHY",
  UNHEALTHY = "UNHEALTHY",
}

export interface FaucetHealthResponse {
  status: FaucetHealthStatus;
  content: string;
}

export interface FaucetRequestItem {
  requestTime: Date;
  clientRequestId: string;
  toAddress: string;
  response: FaucetRequestSuccessResponse;
}

export interface FaucetRequestError {
  requestTime: Date;
  clientRequestId: string;
  toAddress: string;
  response: FaucetRequestErrorResponse;
}
