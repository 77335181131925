export type Env = "development" | "staging" | "production";

const EnvMap: Record<string, Env> = {
  localhost: "development",
  "test-faucet.alchemy.com": "staging",
  "faucets.alchemy.com": "production",
  "www.alchemy.com": "production",
  "alchemy.com": "production",
};

export const ENV = EnvMap[window.location.hostname] || "development";
