import {
  ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK,
  BasicFaucetAppNetworkInfo,
} from "../../shared/models/types";

export interface FaucetLinkProps {
  network: BasicFaucetAppNetworkInfo;
  currentNetwork: BasicFaucetAppNetworkInfo;
  className: string;
  flipSelectedNetwork: () => void;
}

export default function FaucetLinkComponent({
  network,
  currentNetwork,
  className,
  flipSelectedNetwork,
}: FaucetLinkProps): JSX.Element {
  const faucetLinkComponent =
    currentNetwork.id === network.id ? (
      <div
        className={className}
        role="button"
        onMouseOver={flipSelectedNetwork}
        onMouseLeave={flipSelectedNetwork}
      >
        {network.fullName}
      </div>
    ) : (
      <a
        href={`/faucets/${
          ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK[network.id]
        }`}
        rel="noopener noreferrer"
        className={className}
        role="button"
        onMouseOver={flipSelectedNetwork}
        onMouseLeave={flipSelectedNetwork}
      >
        {network.fullName}
      </a>
    );

  return faucetLinkComponent;
}
