import {
  AuthedUser,
  FaucetRequestErrorResponse,
} from "../../shared/models/types";
import FaucetAnalytics from "./FaucetAnalytics";

enum FaucetFrontEndLoggerEvents {
  FAUCET_REQUEST_BEGIN = "faucet_request_begin",
  FAUCET_REQUEST_SUCCESS = "faucet_request_success",
  FAUCET_REQUEST_FAIL = "faucet_request_fail",
  FAUCET_AUTHENTICATED_REQUEST_BEGIN = "faucet_authenticated_request_begin",
  FAUCET_AUTHENTICATED_REQUEST_SUCCESS = "faucet_authenticated_request_success",
  FAUCET_AUTHENTICATED_REQUEST_FAIL = "faucet_authenticated_request_fail",
  FAUCET_AUTHENTICATION_START = "faucet_authentication_start",
  FAUCET_AUTHENTICATION_LOGOUT = "faucet_authentication_logout",
  FACUET_VALIDATION_EVENT = "faucet_validation_event",
}

const analytics = new FaucetAnalytics();
analytics.initialize();

function logEvent(eventName: string, data: Record<string, string | number>) {
  const wrappedData = {
    ...data,
    environment: process.env.NODE_ENV,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  gtag("event", eventName, wrappedData);

  analytics.logEvent({
    eventName: eventName,
    eventData: wrappedData,
  });
}

const FaucetFrontEndLogger = {
  logRequestFail(
    client_request_id: string,
    faucetError: FaucetRequestErrorResponse,
    alchemyUserId?: string,
  ) {
    if (alchemyUserId) {
      logEvent(FaucetFrontEndLoggerEvents.FAUCET_AUTHENTICATED_REQUEST_FAIL, {
        client_request_id: client_request_id,
        toAddress: faucetError.toAddress,
        reason: faucetError.message,
        code: faucetError.code,
        network: faucetError.networkInfo?.network,
        chain: faucetError.networkInfo?.chain,
        user_id: alchemyUserId,
      });
    } else {
      logEvent(FaucetFrontEndLoggerEvents.FAUCET_REQUEST_FAIL, {
        client_request_id: client_request_id,
        toAddress: faucetError.toAddress,
        reason: faucetError.message,
        code: faucetError.code,
        network: faucetError.networkInfo?.network,
        chain: faucetError.networkInfo?.chain,
      });
    }
  },
  setAnalyticsUser(user: AuthedUser): void {
    analytics.setAnalyticsUser(user);
  },
};
export default FaucetFrontEndLogger;
