import posthog from "posthog-js";
import { ENV } from "./Settings";
import { AuthedUser } from "../../shared/models/types";

enum FaucetAnalyticsState {
  LOADED = "loaded",
  IDLE = "idle",
}

interface FaucetAnalyticsData {
  eventName: string;
  eventData: Record<string, string | number>;
}

const posthogKey =
  ENV === "production"
    ? "phc_4Zsoj8TsNoQqKQFsLFiYaw1PX9AGq0q63YBj0HBDFGx"
    : "phc_JiqaPh6g0lRVWQ5xRId6DQXORXNja0ERlLMCi71SL9U";

export default class FaucetAnalytics {
  state: FaucetAnalyticsState = FaucetAnalyticsState.IDLE;
  unintializedQueue: FaucetAnalyticsData[] = [];

  constructor() {}

  initialize() {
    posthog.init(posthogKey, {
      api_host: "https://app.posthog.com",
      disable_session_recording: !this.sessionRecordingEnabled(),
      autocapture: false,
      loaded: () => {
        this.state = FaucetAnalyticsState.LOADED;
        this.purgeQueue();
      },
    });
  }

  private purgeQueue(): void {
    for (const item of this.unintializedQueue) {
      this._logEvent(item);
    }
  }

  private _logEvent(data: FaucetAnalyticsData): void {
    posthog.capture(data.eventName, data.eventData);
  }

  logEvent(data: FaucetAnalyticsData): void {
    switch (this.state) {
      case FaucetAnalyticsState.LOADED: {
        this._logEvent(data);
        break;
      }
      case FaucetAnalyticsState.IDLE: {
        this.unintializedQueue.push(data);
      }
    }
  }

  setAnalyticsUser(user: AuthedUser): void {
    posthog.identify(user.extId, {
      internalId: user.id,
      teamId: user.teamId || undefined,
      email: user.email,
      isStaff: user.isStaff,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }

  logout() {
    posthog.reset();
  }

  // Live session recording uses a lot of posthog events, so only record a
  // subset of user sessions.
  private sessionRecordingEnabled() {
    const recordRatio = 0.03;
    const flagKey = "enableSessionRecording";
    if (!localStorage.getItem(flagKey)) {
      const setShouldRecord = Math.random() <= recordRatio;
      localStorage.setItem(flagKey, setShouldRecord ? "yes" : "no");
    }
    return localStorage.getItem(flagKey) === "yes";
  }
}
