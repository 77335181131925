import {
  CLIENT_REQUEST_ID_PREFIX,
  FaucetRequestItem,
} from "./FaucetRequestTypes";

import ls from "localstorage-slim";
import { FaucetChainNetworkKey } from "../../shared/models/types";

const FaucetLocalStorage = {
  EXPIRE_PERIOD_MILLISECONDS: 259200000, // 3 days

  getNamespace(chainNetwork: string): string {
    return `${CLIENT_REQUEST_ID_PREFIX}${chainNetwork}_`;
  },

  getRequests(chainNetwork: FaucetChainNetworkKey): FaucetRequestItem[] {
    const items: FaucetRequestItem[] = [];
    const minRenderDate = new Date(
      Date.now() - this.EXPIRE_PERIOD_MILLISECONDS,
    ).getTime();
    const namespace = FaucetLocalStorage.getNamespace(chainNetwork);

    Object.keys(localStorage).forEach(function (key) {
      if (
        !key.includes(namespace) ||
        !ls.get(key) ||
        typeof ls.get(key) !== "object"
      ) {
        return;
      }
      const request: FaucetRequestItem = ls.get(key) as FaucetRequestItem;
      if (typeof request.requestTime === "string") {
        request.requestTime = new Date(request.requestTime);
      }
      if (request.requestTime.getTime() > minRenderDate) {
        items.push(request);
      }
    });
    items.sort((a: FaucetRequestItem, b: FaucetRequestItem) => {
      return a.requestTime > b.requestTime ? -1 : 1;
    });
    return items;
  },

  addRequest(
    chainNetwork: FaucetChainNetworkKey,
    item: FaucetRequestItem,
  ): boolean {
    const namespace = FaucetLocalStorage.getNamespace(chainNetwork);

    ls.set(`${namespace}${item.clientRequestId}`, item, {
      ttl: this.EXPIRE_PERIOD_MILLISECONDS / 1000,
    });
    return true;
  },
};

export default FaucetLocalStorage;
