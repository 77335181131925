import { UrlPaths } from "../../shared/constants";
import { UserProperties } from "../../shared/models/types";
import moment from "moment";

export function formURL(path: string, serverURL: string): string {
  if (serverURL === UrlPaths.ROOT) {
    return path;
  }
  const url = new URL(path, serverURL);
  return url.toString();
}

export function getRandomElement<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

// Returns the same number each time during that day, but each day is different
export function getDeterministicNewDayElement(seed: number): number {
  return parseInt(moment().format("D")) + seed;
}

export function isActiveTeam(properties?: UserProperties) {
  return properties && properties.fcuUsage > 0;
}
