import axios from "axios";
import { UrlPaths } from "../../shared/constants";
import { formURL } from "./Util";
import {
  FaucetChainNetworkKey,
  FaucetClientServerData,
} from "../../shared/models/types";

export async function getServerData(
  chainNetwork?: FaucetChainNetworkKey,
): Promise<FaucetClientServerData> {
  const serverData = (window as unknown as { SERVER_DATA: unknown })
    .SERVER_DATA;
  if (serverData === UrlPaths.SERVER_DATA_KEY) {
    // get from server route
    return axios
      .get(
        formURL(
          // If chainNetwork is nil, we can't add the /undefined to the URL
          `${chainNetwork ? `/${chainNetwork}` : ""}${
            UrlPaths.API_V1.REQUEST_SERVER_DATA
          }`,
          UrlPaths.LOCAL_DEV_SERVER_URL,
        ),
      )
      .then((response) => {
        return response.data as unknown as FaucetClientServerData;
      });
  } else {
    return serverData as FaucetClientServerData;
  }
}
