interface AlchemyLogoComponentProps {
  affiliateReferral: string;
}

function AlchemyLogoComponent(props: AlchemyLogoComponentProps): JSX.Element {
  return (
    <a
      href={`https://alchemy.com/?a=${props.affiliateReferral}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="68"
        height="18"
        viewBox="0 4 155 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.4509 17.7107L16.3896 0.285206C16.3409 0.199161 16.2703 0.127487 16.1851 0.0774147C16.0998 0.0273425 16.0028 0.000643265 15.904 1.14791e-05C15.8051 -0.000620306 15.7078 0.0248374 15.6219 0.073816C15.536 0.122795 15.4646 0.193562 15.4148 0.278977L12.4015 5.50039C12.3028 5.67131 12.2509 5.86519 12.2509 6.06255C12.2509 6.25991 12.3028 6.45379 12.4015 6.62471L18.9621 17.9925C19.0609 18.1636 19.203 18.3057 19.3742 18.4043C19.5453 18.503 19.7394 18.5549 19.937 18.5547H25.9635C26.0621 18.5544 26.159 18.5282 26.2444 18.4788C26.3297 18.4293 26.4006 18.3583 26.45 18.2729C26.4993 18.1875 26.5254 18.0906 26.5255 17.9919C26.5257 17.8932 26.4999 17.7963 26.4509 17.7107Z"
          fill="white"
        />
        <path
          d="M0.077583 26.8179L10.1389 9.3924C10.1882 9.30704 10.2592 9.23617 10.3446 9.1869C10.43 9.13762 10.5269 9.11169 10.6255 9.11169C10.7241 9.11169 10.821 9.13762 10.9064 9.1869C10.9918 9.23617 11.0628 9.30704 11.1122 9.3924L14.127 14.6091C14.2256 14.7803 14.2776 14.9745 14.2776 15.1721C14.2776 15.3697 14.2256 15.5638 14.127 15.735L7.56632 27.1028C7.46792 27.2739 7.32612 27.416 7.15523 27.5147C6.98434 27.6134 6.7904 27.6652 6.59305 27.665H0.564998C0.465696 27.6655 0.368039 27.6397 0.281951 27.5902C0.195863 27.5407 0.124426 27.4693 0.0749027 27.3832C0.0253789 27.2971 -0.000466229 27.1995 6.36682e-06 27.1002C0.000478962 27.0009 0.0272422 26.9035 0.077583 26.8179Z"
          fill="white"
        />
        <path
          d="M11.1107 27.6613H31.2333C31.332 27.6612 31.429 27.6351 31.5144 27.5857C31.5998 27.5362 31.6707 27.4651 31.72 27.3796C31.7692 27.294 31.7951 27.197 31.795 27.0983C31.7948 26.9996 31.7687 26.9027 31.7192 26.8173L28.709 21.5974C28.6103 21.4263 28.4682 21.2843 28.297 21.1856C28.1259 21.0869 27.9318 21.035 27.7342 21.0352H14.6129C14.4154 21.035 14.2213 21.0869 14.0501 21.1856C13.879 21.2843 13.7369 21.4263 13.6381 21.5974L10.6249 26.8173C10.5754 26.9027 10.5492 26.9996 10.5491 27.0983C10.549 27.197 10.5748 27.294 10.624 27.3796C10.6733 27.4651 10.7442 27.5362 10.8296 27.5857C10.9151 27.6351 11.012 27.6612 11.1107 27.6613Z"
          fill="white"
        />
        <path
          d="M48.7122 17.1047V16.7148C48.7122 15.735 48.3822 14.9851 47.7223 14.4652C47.0824 13.9253 46.2426 13.6553 45.2027 13.6553C44.4028 13.6553 43.773 13.7953 43.313 14.0752C42.8731 14.3352 42.4532 14.7351 42.0532 15.275C41.9332 15.435 41.8033 15.545 41.6633 15.605C41.5233 15.665 41.3333 15.695 41.0934 15.695H40.1935C39.9736 15.695 39.7736 15.615 39.5936 15.455C39.4336 15.295 39.3637 15.1051 39.3837 14.8851C39.4436 14.2852 39.7336 13.6953 40.2535 13.1154C40.7934 12.5155 41.5033 12.0256 42.3832 11.6456C43.263 11.2657 44.2029 11.0757 45.2027 11.0757C47.0624 11.0757 48.5622 11.5956 49.702 12.6355C50.8618 13.6753 51.4417 15.1351 51.4417 17.0148V26.1633C51.4417 26.3833 51.3617 26.5732 51.2018 26.7332C51.0418 26.8932 50.8518 26.9732 50.6318 26.9732H49.522C49.3021 26.9732 49.1121 26.8932 48.9521 26.7332C48.7921 26.5732 48.7122 26.3833 48.7122 26.1633V24.9335C48.3922 25.6534 47.7123 26.2233 46.6725 26.6432C45.6327 27.0631 44.5928 27.2731 43.553 27.2731C42.5332 27.2731 41.6233 27.0931 40.8234 26.7332C40.0236 26.3533 39.4037 25.8433 38.9637 25.2034C38.5438 24.5635 38.3338 23.8437 38.3338 23.0438C38.3338 21.544 38.8937 20.3742 40.0136 19.5344C41.1334 18.6745 42.6231 18.0946 44.4828 17.7946L48.7122 17.1047ZM48.7122 19.5044L45.1727 20.0743C43.8729 20.2742 42.8631 20.6042 42.1432 21.0641C41.4233 21.504 41.0634 22.0639 41.0634 22.7438C41.0634 23.2438 41.2934 23.6937 41.7533 24.0936C42.2132 24.4936 42.9131 24.6935 43.8529 24.6935C45.2927 24.6935 46.4625 24.2836 47.3624 23.4637C48.2622 22.6439 48.7122 21.574 48.7122 20.2542V19.5044Z"
          fill="white"
        />
        <path
          d="M58.5475 26.1633C58.5475 26.3833 58.4675 26.5732 58.3076 26.7332C58.1476 26.8932 57.9576 26.9732 57.7377 26.9732H56.6278C56.4079 26.9732 56.2179 26.8932 56.0579 26.7332C55.898 26.5732 55.818 26.3833 55.818 26.1633V6.48645C55.818 6.26649 55.898 6.07652 56.0579 5.91654C56.2179 5.75657 56.4079 5.67658 56.6278 5.67658H57.7377C57.9576 5.67658 58.1476 5.75657 58.3076 5.91654C58.4675 6.07652 58.5475 6.26649 58.5475 6.48645V26.1633Z"
          fill="white"
        />
        <path
          d="M69.3521 24.6935C71.3518 24.6935 72.7016 23.9136 73.4015 22.3539C73.5414 22.0539 73.6814 21.844 73.8214 21.724C73.9614 21.604 74.1513 21.544 74.3913 21.544H75.2912C75.5111 21.544 75.7011 21.624 75.8611 21.784C76.021 21.924 76.101 22.0939 76.101 22.2939C76.101 22.9738 75.8311 23.7037 75.2912 24.4836C74.7512 25.2634 73.9714 25.9233 72.9515 26.4632C71.9317 27.0032 70.7319 27.2731 69.3521 27.2731C67.9323 27.2731 66.7025 26.9732 65.6627 26.3733C64.6229 25.7733 63.823 24.9535 63.2631 23.9136C62.7032 22.8538 62.3932 21.674 62.3332 20.3742C62.3133 20.1343 62.3032 19.6743 62.3032 18.9944C62.3032 18.5145 62.3133 18.1746 62.3332 17.9746C62.5132 15.9349 63.2031 14.2752 64.4029 12.9954C65.6027 11.7156 67.2525 11.0757 69.3521 11.0757C70.7319 11.0757 71.9217 11.3457 72.9215 11.8856C73.9414 12.4055 74.7113 13.0554 75.2312 13.8353C75.7711 14.5951 76.061 15.315 76.101 15.9949C76.121 16.2149 76.041 16.4049 75.8611 16.5648C75.7011 16.7248 75.5111 16.8048 75.2912 16.8048H74.3913C74.1513 16.8048 73.9614 16.7448 73.8214 16.6248C73.6814 16.5048 73.5414 16.2949 73.4015 15.9949C72.7016 14.4352 71.3518 13.6553 69.3521 13.6553C68.2523 13.6553 67.2924 14.0252 66.4726 14.7651C65.6527 15.505 65.1828 16.6248 65.0628 18.1246C65.0428 18.3445 65.0328 18.7045 65.0328 19.2044C65.0328 19.6643 65.0428 20.0043 65.0628 20.2242C65.2028 21.724 65.6727 22.8438 66.4726 23.5837C67.2924 24.3236 68.2523 24.6935 69.3521 24.6935Z"
          fill="white"
        />
        <path
          d="M93.5489 26.1633C93.5489 26.3833 93.4689 26.5732 93.3089 26.7332C93.1489 26.8932 92.9589 26.9732 92.739 26.9732H91.6292C91.4092 26.9732 91.2192 26.8932 91.0592 26.7332C90.8993 26.5732 90.8193 26.3833 90.8193 26.1633V18.2446C90.8193 16.7448 90.4493 15.605 89.7095 14.8251C88.9696 14.0452 87.9398 13.6553 86.62 13.6553C85.3202 13.6553 84.2903 14.0452 83.5305 14.8251C82.7906 15.605 82.4206 16.7448 82.4206 18.2446V26.1633C82.4206 26.3833 82.3407 26.5732 82.1807 26.7332C82.0207 26.8932 81.8307 26.9732 81.6108 26.9732H80.501C80.281 26.9732 80.091 26.8932 79.931 26.7332C79.7711 26.5732 79.6911 26.3833 79.6911 26.1633V6.48645C79.6911 6.26649 79.7711 6.07652 79.931 5.91654C80.091 5.75657 80.281 5.67658 80.501 5.67658H81.6108C81.8307 5.67658 82.0207 5.75657 82.1807 5.91654C82.3407 6.07652 82.4206 6.26649 82.4206 6.48645V13.1154C82.8606 12.5355 83.4605 12.0556 84.2204 11.6756C85.0002 11.2757 85.9501 11.0757 87.0699 11.0757C88.3297 11.0757 89.4495 11.3457 90.4294 11.8856C91.4092 12.4255 92.1691 13.2054 92.709 14.2252C93.2689 15.225 93.5489 16.4149 93.5489 17.7946V26.1633Z"
          fill="white"
        />
        <path
          d="M104.06 11.0757C106.199 11.0757 107.889 11.7556 109.129 13.1154C110.369 14.4752 110.989 16.3249 110.989 18.6645V19.4744C110.989 19.6943 110.909 19.8843 110.749 20.0443C110.589 20.2042 110.399 20.2842 110.179 20.2842H99.8605V20.4642C99.9005 21.784 100.3 22.8238 101.06 23.5837C101.84 24.3236 102.84 24.6935 104.06 24.6935C105.06 24.6935 105.83 24.5635 106.369 24.3036C106.929 24.0436 107.429 23.6737 107.869 23.1938C108.029 23.0338 108.169 22.9238 108.289 22.8638C108.429 22.8038 108.609 22.7738 108.829 22.7738H109.729C109.969 22.7738 110.169 22.8538 110.329 23.0138C110.489 23.1738 110.559 23.3637 110.539 23.5837C110.459 24.1236 110.159 24.6835 109.639 25.2634C109.139 25.8233 108.409 26.3033 107.449 26.7032C106.509 27.0831 105.38 27.2731 104.06 27.2731C102.78 27.2731 101.64 26.9832 100.64 26.4032C99.6405 25.8033 98.8406 24.9835 98.2407 23.9436C97.6608 22.9038 97.3109 21.734 97.1909 20.4342C97.1509 19.8343 97.1309 19.3744 97.1309 19.0544C97.1309 18.7345 97.1509 18.2746 97.1909 17.6747C97.3109 16.4349 97.6608 15.315 98.2407 14.3152C98.8406 13.3154 99.6305 12.5255 100.61 11.9456C101.61 11.3657 102.76 11.0757 104.06 11.0757ZM108.289 17.7646V17.6747C108.289 16.4548 107.899 15.485 107.119 14.7651C106.359 14.0252 105.34 13.6553 104.06 13.6553C102.9 13.6553 101.91 14.0252 101.09 14.7651C100.29 15.505 99.8805 16.4748 99.8605 17.6747V17.7646H108.289Z"
          fill="white"
        />
        <path
          d="M117.337 13.1154C117.817 12.4955 118.357 12.0056 118.957 11.6456C119.557 11.2657 120.356 11.0757 121.356 11.0757C123.696 11.0757 125.336 11.9456 126.275 13.6853C126.855 12.8454 127.515 12.2055 128.255 11.7656C128.995 11.3057 129.965 11.0757 131.165 11.0757C133.124 11.0757 134.574 11.6656 135.514 12.8454C136.474 14.0252 136.954 15.695 136.954 17.8546V26.1633C136.954 26.3833 136.874 26.5732 136.714 26.7332C136.554 26.8932 136.364 26.9732 136.144 26.9732H135.034C134.814 26.9732 134.624 26.8932 134.464 26.7332C134.304 26.5732 134.224 26.3833 134.224 26.1633V18.1546C134.224 15.1551 133.044 13.6553 130.685 13.6553C129.605 13.6553 128.745 14.0152 128.105 14.7351C127.465 15.455 127.145 16.5248 127.145 17.9446V26.1633C127.145 26.3833 127.065 26.5732 126.905 26.7332C126.745 26.8932 126.555 26.9732 126.335 26.9732H125.226C125.006 26.9732 124.816 26.8932 124.656 26.7332C124.496 26.5732 124.416 26.3833 124.416 26.1633V18.1546C124.416 15.1551 123.236 13.6553 120.876 13.6553C119.797 13.6553 118.937 14.0152 118.297 14.7351C117.657 15.455 117.337 16.5248 117.337 17.9446V26.1633C117.337 26.3833 117.257 26.5732 117.097 26.7332C116.937 26.8932 116.747 26.9732 116.527 26.9732H115.417C115.197 26.9732 115.007 26.8932 114.847 26.7332C114.687 26.5732 114.607 26.3833 114.607 26.1633V12.1855C114.607 11.9656 114.687 11.7756 114.847 11.6156C115.007 11.4557 115.197 11.3757 115.417 11.3757H116.527C116.747 11.3757 116.937 11.4557 117.097 11.6156C117.257 11.7756 117.337 11.9656 117.337 12.1855V13.1154Z"
          fill="white"
        />
        <path
          d="M145.355 31.9524C145.175 32.4323 144.885 32.6722 144.485 32.6722H143.255C143.055 32.6722 142.885 32.6023 142.745 32.4623C142.605 32.3223 142.535 32.1523 142.535 31.9524C142.535 31.8724 142.545 31.8024 142.565 31.7424L145.444 25.4734L139.505 12.3055C139.485 12.2455 139.475 12.1755 139.475 12.0955C139.475 11.8956 139.545 11.7256 139.685 11.5856C139.825 11.4457 139.995 11.3757 140.195 11.3757H141.425C141.825 11.3757 142.115 11.6156 142.295 12.0955L146.914 22.2939L151.594 12.0955C151.773 11.6156 152.063 11.3757 152.463 11.3757H153.693C153.893 11.3757 154.063 11.4457 154.203 11.5856C154.343 11.7256 154.413 11.8956 154.413 12.0955C154.413 12.1755 154.403 12.2455 154.383 12.3055L145.355 31.9524Z"
          fill="white"
        />
      </svg>
    </a>
  );
}

export default AlchemyLogoComponent;
