import { Button } from "react-bootstrap";
import { UserProperties } from "../../shared/models/types";

export enum AuthState {
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
  LOADING = "loading",
  INCOMPLETE = "incomplete",
  ERROR = "error",
}

export interface AuthAuthenticated {
  authState: AuthState.AUTHENTICATED;
  firstName: string;
  userId: string;
  loginRedirect: () => void;
  logout: () => void;
  userProperties?: UserProperties;
}

export interface AuthUnauthenticated {
  authState: AuthState.UNAUTHENTICATED;
  loginRedirect: () => void;
  logout: () => void;
}

export interface AuthError {
  authState: AuthState.ERROR;
  errorMessage: string;
  loginRedirect: () => void;
  logout: () => void;
}

export interface AuthLoading {
  authState: AuthState.LOADING;
  loginRedirect: () => void;
  logout: () => void;
}

export interface AuthIncomplete {
  authState: AuthState.INCOMPLETE;
  errorMessage: string;
  loginRedirect: () => void;
  logout: () => void;
}

export type AuthCheckProps =
  | AuthAuthenticated
  | AuthUnauthenticated
  | AuthError
  | AuthLoading
  | AuthIncomplete;

/**
 * Gets a stored Auth Token for local storage.  We do this instead of cookies.
 */
export function getStoredAuthToken(): string | undefined {
  return localStorage.getItem("authToken") || undefined;
}

export default function AuthCheckComponent(props: AuthCheckProps): JSX.Element {
  switch (props.authState) {
    case AuthState.AUTHENTICATED:
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: 10,
              fontSize: 14,
            }}
          >
            Welcome,{" "}
            {props.authState == AuthState.AUTHENTICATED && props.firstName}
          </div>
          <Button size="sm" variant="outline-light" onClick={props.logout}>
            Logout
          </Button>
        </div>
      );
    case AuthState.UNAUTHENTICATED:
    case AuthState.LOADING:
    case AuthState.INCOMPLETE:
    case AuthState.ERROR:
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 10, fontSize: 14 }}>
            {"errorMessage" in props && props.errorMessage}
          </div>
          <Button
            variant="outline-light"
            size="sm"
            disabled={false}
            onClick={props.loginRedirect}
          >
            {props.authState != AuthState.INCOMPLETE && "Alchemy Login"}
            {props.authState == AuthState.INCOMPLETE && "Complete Onboarding"}
          </Button>
        </div>
      );
  }
}
