function FaucetLoadingSpinner(props: {
  color: string;
  width: number;
  height: number;
  thickness: number;
  margin?: string;
}) {
  const componentStyle = {
    borderColor: props.color + " transparent transparent transparent",
    height: props.height,
    width: props.width,
    borderWidth: props.thickness,
  };
  return (
    <div
      className="alchemy-faucet-lds-ring"
      style={{
        height: props.height,
        width: props.width,
        margin: props.margin ? props.margin : "inherit",
      }}
    >
      <div style={componentStyle}></div>
      <div style={componentStyle}></div>
      <div style={componentStyle}></div>
      <div style={componentStyle}></div>
    </div>
  );
}

export default FaucetLoadingSpinner;
