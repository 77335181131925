import React from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FaucetApp from "./app/FaucetApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const container = document.getElementById("root");
const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/*
          There are multiple Route definition to handle multiple use-cases:
            * hitting frontend dev server e.g. localhost:3000
            * hitting backend server e.g. localhost:5001
            * hitting cloudflare worker to handle routes from alchemy.com/faucets
        */}

          {/* Always need a defaulth handler */}
          <Route path="/" element={<FaucetApp />} />
          {/* Handles when you hit the server directly e.g. localhost:5001 */}
          <Route path="/:chainNetwork" element={<FaucetApp />} />
          {/* Most users will use this one. Our navbar will link to this route */}
          <Route path="/faucets/:chainNetwork" element={<FaucetApp />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
